@import '../../assets/style/variables.module.scss';

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #f9f9f9;
  min-height: 100vh;
  overflow-x: hidden;
}

@media only screen and (max-width: 960px) {
  .pageWrapper {
    overflow-y: hidden;
  }
}
