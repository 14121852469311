@import '../../assets/style/variables.module.scss';

.categories {
  width: 100%;
  padding: 0 50px;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .gridWrapper {
    width: 100%;
    padding-top: 150px;
    overflow-x: scroll;
    padding-bottom: 50px;
    box-sizing: border-box;
    padding-right: 50px;

    &:nth-child(2) {
      position: relative;
      right: -10px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 960px) {
  .categories {
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
    justify-content: flex-start;

    .gridWrapper {
      padding: 0;
      width: 100%;
      position: static !important;
    }

    .box {
      box-sizing: border-box;
      padding: 0 20px;
    }

    .masonry {
      margin: 0 !important;
    }
  }
}
