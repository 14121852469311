@import '../../assets/style/variables.module.scss';

.categoryBar {
  height: 100%;
  overflow-x: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    gap: 25px;
    padding-top: 50px;
    padding-bottom: 50px;

    li {
      button {
        color: $--text-color;
        font-size: 46px;
        font-weight: 200;
        transition: color 0.5s ease;
        white-space: nowrap;
        color: #cfcfcf;
      }

      .hover {
        color: $--secondary-color;
        font-weight: 700;
        font-style: italic;
      }
    }

    .active {
      a {
        color: $--secondary-color;
        font-weight: 700;
        font-style: italic;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .categoryBar {
    max-width: 100vw;
    margin-bottom: 30px;
    margin-top: 50px;

    ul {
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
      padding-top: 0;
      padding-bottom: 0;

      li {
        button {
          font-size: 24px;
        }
        &:last-child {
          button {
            margin-right: 30px;
          }
        }
      }
    }
  }
}
