@import '../../assets/style/variables.module.scss';

.search {
  padding-top: 115px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .searchResultWrapper {
    display: flex;
    width: 100%;
    padding: 0 50px;
    box-sizing: border-box;
    gap: 60px;
    .userResultWrapper,
    .workResultWrapper {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    .workResultWrapper {
      width: 100%;

      .workWrapper {
        width: 100%;

        .masonry {
          width: calc(100% + 30px);
          position: relative;
        }
      }
    }
  }

  .searchResult {
    width: 100%;
    margin-bottom: 25px;
    padding: 0 50px;
    box-sizing: border-box;

    .resultTitle {
      font-size: 24px;
      color: $--text-color;
      font-weight: 500;

      span {
        font-weight: 700;
        color: $--main-blue;
      }
    }
  }

  .searchTitle {
    font-size: 24px;
    color: $--text-color;
    font-weight: 500;
    width: 90%;
    margin-bottom: 25px;
  }

  .searchMultiGrid {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    gap: 30px;
  }

  .top {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
    padding: 0 60px;
    box-sizing: border-box;
  }

  .recent {
    max-width: 1400px;
    width: 100%;
    h2 {
      font-size: 24px;
      color: $--text-color;
      font-weight: 500;
    }

    .recentGrid {
      display: flex;
      gap: 6px;
      margin-top: 20px;

      span {
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 35px;
        background: rgba(28, 28, 28, 0.15);
        padding: 5px 8px 3px 12px;
        border-radius: 35px;
        display: flex;
        align-items: center;
        gap: 12px;
        color: rgba(47, 47, 47, 0.5);
      }
    }
  }

  .bottom {
    margin-top: 50px;
    display: flex;
    max-width: 1400px;
    width: 100%;
    gap: 50px;
    .multiWorks {
      width: 350px;
      h2 {
        font-size: 24px;
        color: $--text-color;
        font-weight: 500;
      }

      .multiGrid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-top: 20px;

        button {
          position: relative;

          &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(
              360deg,
              #113cf5 -7.58%,
              rgba(17, 60, 245, 0) 79.95%
            );
            border-radius: 10px;
          }

          img {
            border-radius: 10px;
            width: 170px;
            height: 170px;
            object-fit: cover;
            object-position: center;
          }

          h5 {
            position: absolute;
            left: 15px;
            bottom: 15px;
            color: white;
            font-size: 22px;
            font-weight: 400;
            z-index: 1;
          }
        }
      }
    }
    .multiHumans {
      h2 {
        font-size: 24px;
        color: $--text-color;
        font-weight: 500;
      }

      .multiGrid {
        display: flex;
        gap: 10px;
        margin-top: 20px;

        button {
          position: relative;

          img {
            border-radius: 10px;
            width: 170px;
            height: 170px;
            object-fit: cover;
            object-position: center;
          }

          h5 {
            position: absolute;
            left: 15px;
            bottom: 15px;
            color: white;
            font-size: 22px;
            font-weight: 400;
            font-style: italic;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .search {
    padding-top: 100px;
    .searchResult {
      padding: 0 45px;
    }

    .searchResultWrapper {
      padding: 0 45px;
      flex-direction: column;
      gap: 0px;
      .userResultWrapper,
      .workResultWrapper {
        width: 100% !important;
      }

      .userResultWrapper {
        .searchMultiGrid {
          width: 100%;
          gap: 10px;
        }
      }
      .workResultWrapper {
        .workWrapper {
          width: 100%;
          .masonry {
            width: 104%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .search {
    padding-top: 100px;
    .searchResult {
      padding: 0 27px;
    }

    .searchResultWrapper {
      padding: 0 27px;
    }
  }
}
