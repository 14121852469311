@import '../../assets/style/variables.module.scss';

.categoryLine {
  height: 100%;
  width: 450px;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-top: 115px;
    padding-bottom: 50px;

    li {
      a {
        color: $--text-color;
        font-size: 46px;
        font-weight: 300;
        cursor: pointer;
      }

      .active {
        color: $--secondary-color;
        font-weight: 700;
        font-style: italic;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .categoryLine {
    height: unset;
    min-height: 130px;
    margin-bottom: 20px;
    width: 100%;
    overflow-y: hidden;

    ul {
      flex-direction: row;
      padding-bottom: 0;
      padding-left: 37px;
      width: 100%;
      padding-top: 100px;
      li {
        &:last-child {
          a {
            margin-right: 30px !important;
          }
        }
        a {
          white-space: nowrap;
          font-size: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .categoryLine {
    ul {
      padding-left: 28px;
    }
  }
}
