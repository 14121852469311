@import '../../assets/style/variables.module.scss';

.footer {
  position: absolute;
  z-index: 11;
  bottom: 60px;
  width: 100%;
  max-width: calc(80% - 200px);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .logoWrapper {
    display: flex;
    align-items: center;
    gap: 33px;

    p {
      font-size: 10px;
      color: #bebebe;
    }

    a {
      svg {
        path {
          fill: white;
        }
      }
    }
  }

  ul {
    display: flex;
    gap: 26px;
    align-items: center;

    li {
      a {
        color: white;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

@media only screen and (max-width: 1285px) {
  .footer {
    max-width: 820px;
    bottom: 30px;
  }
}
