@import '../../assets/style/variables.module.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: fixed;
  transition: all 0.5s ease;
  z-index: 100;
  border-top: 10px solid $--main-blue;
  padding: 0 25px;
  padding-top: 30px;
  box-sizing: border-box;

  .menu {
    display: none;
  }

  .logo {
    margin-left: 3px;
  }

  .menuButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    svg {
      path {
        fill: $--main-blue;
      }
    }
  }
}

.active {
  background: rgba(17, 60, 245, 0.7);
  border: 0px transparent solid;
  box-shadow: 0px 0px 71px 14px rgba(17, 60, 245, 0.25);
  backdrop-filter: blur(29px);
  border-bottom: 1px solid rgba($color: white, $alpha: 0.3);
  max-height: 72px;
  transition: all 0.5s ease;
  padding-bottom: 50px;

  .logo {
    position: relative;
    bottom: 4px;
    svg {
      path {
        fill: white;
      }
    }
  }

  .menuButton {
    margin-top: 0;
    svg {
      rect,
      path {
        fill: white;
      }
    }
  }
}

.popupActive {
  max-height: unset !important;
  padding: 30px 25px 100vh 25px !important;
  background: linear-gradient(180deg, #1b1f31 0%, rgba(21, 43, 137, 0.6) 100%);
  backdrop-filter: blur(30px);
}

.menuActive {
  background: rgba(17, 60, 245, 0.7);
  backdrop-filter: blur(30px);
  box-sizing: border-box;
  height: 300px;
  max-height: 300px;

  .menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 50px;
    box-sizing: border-box;
    width: calc(100% - 55px);

    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
      li {
        a {
          color: white;
          font-size: 29px;
        }
      }
    }

    .labelWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid white;
      margin-top: 20px;

      input {
        width: 100%;
        height: 45px;
        background: transparent;
        color: white;

        &::placeholder {
          color: white;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) and (min-width: 600px) {
  .header {
    padding: 0 32px;
    padding-top: 30px;
  }

  .active {
    padding-bottom: 50px;
  }
}
