@import '../../assets/style/variables.module.scss';

.maintance {
  width: 100vw;
  height: 100vh;
  background: $--main-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  h1 {
    font-size: 24px;
    color: white;
    font-weight: 400;
  }

  svg {
    path {
      fill: white;
    }
  }
}
