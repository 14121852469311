@import '../../assets/style/variables.module.scss';

.categories {
  width: 100%;
  padding: 0 50px;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .info {
    padding-top: 115px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 100px;

    width: 25%;

    &::-webkit-scrollbar {
      display: none;
    }

    .buttonWrapper {
      display: flex;
      gap: 13px;
      margin-top: 15px;
      flex-wrap: wrap;

      a {
        background: #d9e0ff;
        color: $--main-blue;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 5px 12px;
        border-radius: 5px;

        h5 {
          margin-top: 2px;
        }

        svg {
          width: 20px;
          height: 20px;
          path {
            fill: $--main-blue;
          }
        }
      }
    }

    img {
      width: 255px;
      height: 255px;
      object-fit: cover;
      object-position: center;
      margin-top: 25px;
      border: 1px solid rgba(17, 60, 245, 0.12);
      // filter: drop-shadow(0px 4px 19px rgba(17, 60, 245, 0.26));
      border-radius: 5px;
    }

    h1 {
      font-weight: 400;
      color: $--main-blue;
      font-size: 52px;
    }

    h2 {
      font-weight: 300;
      color: #ff7976;
      font-size: 22px;
      margin-top: 10px;
    }

    h4 {
      margin-top: 14px;
      background: #113cf5;
      border-radius: 5px;
      color: white;
      font-weight: 400;
      font-size: 12px;
      padding: 10px 9px 8px 9px;
    }

    p {
      width: 255px;
      margin-top: 22px;
      color: $--main-blue;
      font-size: 15px;
      line-height: 17px;
    }

    .tagWrapper {
      margin-top: 15x;

      h2 {
        color: $--text-color;
        font-size: 24px;
        font-style: italic;
        font-weight: 400;
      }

      .tagGrid {
        display: flex;
        flex-wrap: wrap;
        width: 250px;
        gap: 12px;
        margin-top: 20px;
        a {
          border-radius: 5px;
          font-size: 12px;
          padding: 10px 9px 8px 9px;
          color: $--text-color;
          background: #d9d9d9;
        }
      }
    }

    .userWrapper {
      margin-top: 35px;

      h2 {
        color: $--text-color;
        font-size: 24px;
        font-style: italic;
        font-weight: 400;
      }

      .userGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 12px;
        margin-top: 20px;
      }
    }
  }

  .right {
    width: 150%;
    padding-top: 115px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-bottom: 50px;
    padding-left: 0;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    right: -70px;

    .headWrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 30px;
      padding: 0 10px;
      width: calc(100% - 30px);
      gap: 30px;
    }
  }

  .gridWrapper {
    width: 100%;

    &:nth-child(2) {
      position: relative;
      right: -10px;
      box-sizing: border-box;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 960px) {
  .categories {
    flex-direction: column;
    padding: 0 20px;
    height: 100%;

    .info {
      width: 100%;
      padding-bottom: 0;
      overflow: visible;
      padding-top: 100px;

      .mobileInfo {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        width: 100%;

        .infoLeft {
          width: 130px;
          min-width: 130px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .tagWrapper {
            width: 80vw;
            position: relative;

            .tagGrid {
              width: 100%;
            }
          }

          img {
            width: 100%;
            height: 130px;
            margin-top: 0;
            border-radius: 10px;
          }
        }

        .infoRight {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          h1,
          h2,
          h4,
          p {
            width: 100%;
            box-sizing: border-box;
          }

          h2 {
            margin-top: 5px;
          }

          h4 {
            width: unset;
            font-size: 10px;
            margin-top: 10px;
          }

          p {
            margin-top: 10px;
          }
        }
      }

      h1 {
        font-size: 24px;
      }

      h2 {
        font-size: 16px;
      }
    }

    .right {
      padding: 0;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: visible;
      margin-top: 30px;
      right: unset;

      .headWrapper {
        position: static;
        padding: 0;
        width: 100%;
        margin-bottom: 20px;
      }

      .gridWrapper {
        position: relative;
        width: calc(100% + 15px);
        left: -15px;
      }
      .box {
        padding: 0px !important;
        box-sizing: border-box;
      }

      .masonry {
        margin: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .gridWrapper {
    position: relative;
    width: calc(100% + 15px);
    left: -7.5px !important;
  }
}
