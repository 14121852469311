@import '../../assets/style/variables.module.scss';

.wrapper {
  width: 100%;
  padding: 0 50px;
  padding-top: 70px;
  box-sizing: border-box;
  min-height: 100vh;

  .userWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 25px;
    box-sizing: border-box;
    padding-bottom: 100px;
  }

  .masonryWrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    padding: 0;
    padding-top: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    min-height: unset;

    .masonryWrapper {
      padding: 0 20px;
      box-sizing: border-box;

      .masonry {
        width: calc(100% + 10px);
        position: relative;
      }
    }
  }
}
