@import '../../assets/style/variables.module.scss';

.login {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  .bottomForm {
    width: 100%;
    position: absolute;
    height: 200px;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      360deg,
      #113cf5 2.15%,
      rgba(255, 255, 255, 0) 101.76%,
      #113cf5 101.76%
    );
    z-index: 10;
    transition: all 0.7s ease;
    .wrapper {
      position: relative;
      bottom: -1000px;
      transition: all 1s ease;

      h1 {
        color: white;
        font-size: 62px;
        font-weight: 500;
      }
      p {
        font-size: 16px;
        color: white;
        max-width: 420px;
        font-weight: 300;
        line-height: 151%;
        margin-top: 30px;
      }

      form {
        width: 600px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        button {
          margin-top: 25px;
          color: $--main-blue;
          background: white;
          padding: 15px 22px 11px 22px;
          font-size: 20px;
          border-radius: 10px;
          font-weight: 200;
        }

        label {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          width: 100%;

          h5 {
            color: white;
            font-weight: 300;
          }

          input {
            height: 65px;
            width: 100%;
            border: 1px solid #bdbdbd;
            border-radius: 10px;
            padding-left: 25px;
            box-sizing: border-box;
            &::placeholder {
              font-style: italic;
              font-weight: 300;
              color: #b3b3b3;
            }
          }
        }
      }
    }
  }

  .active {
    background: linear-gradient(
      360deg,
      #113cf5 2.15%,
      rgba(17, 60, 245, 0.73) 101.76%
    );
    border: 1px solid rgba(255, 255, 255, 0.51);
    box-shadow: 0px 0px 71px 14px rgba(17, 60, 245, 0.25);
    backdrop-filter: blur(29px);
    border-radius: 100px 100px 0px 0px;
    height: 780px;
    width: 80%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 100px 0 0 100px;
    box-sizing: border-box;

    .wrapper {
      bottom: 0;
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 60px;
    transition: all 1s ease;

    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      h1 {
        color: $--main-blue;
        font-size: 62px;
        font-weight: 500;
      }
      p {
        max-width: 500px;
        text-align: center;
        font-weight: 400;
        line-height: 149.5%;
        margin-top: 30px;
        color: $--text-color;
      }

      .buttonWrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 35px;

        a {
          font-size: 16px;
          font-weight: 300;
          color: $--main-blue;
          text-decoration: underline;
        }

        button {
          background: $--main-blue;
          padding: 13px 18px 13px 18px;
          color: white;
          border-radius: 10px;
          font-size: 16px;
        }
      }
    }
  }

  .topActive {
    top: -700px;
  }

  .gridWrapper {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    position: relative;
    bottom: -180px;
    transition: all 1s ease;

    img {
      width: 100%;
    }
  }

  .gridActive {
    transform: translateY(-800px);
  }

  .error {
    color: rgb(221, 221, 221);
    font-size: 16px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1285px) {
  .login {
    .top {
      padding: 0 30px;
      .info {
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 14px;
        }
      }
    }

    .bottomForm {
      padding: 20px;
      width: calc(100% - 30px);

      .wrapper {
        h1 {
          font-size: 36px;
        }
        p {
          font-size: 14px;
          margin-top: 17px;
        }

        form {
          width: 100%;

          label {
            height: 50px;
          }
        }
      }
    }

    .active {
      border-radius: 30px 30px 0px 0px;
      padding: 0 30px;
      padding-top: 60px;
    }
    .active {
      height: 700px;
    }
  }
}
