@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-BookItalic.woff2') format('woff2'),
    url('../font/planc/Planc-BookItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-Bold.woff2') format('woff2'),
    url('../font/planc/Planc-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-Book.woff2') format('woff2'),
    url('../font/planc/Planc-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-Light.woff2') format('woff2'),
    url('../font/planc/Planc-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-ExtraBold.woff2') format('woff2'),
    url('../font/planc/Planc-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-ExtraLight.woff2') format('woff2'),
    url('../font/planc/Planc-ExtraLight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-Medium.woff2') format('woff2'),
    url('../font/planc/Planc-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-MediumItalic.woff2') format('woff2'),
    url('../font/planc/Planc-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-SemiBold.woff2') format('woff2'),
    url('../font/planc/Planc-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-SemiLight.woff2') format('woff2'),
    url('../font/planc/Planc-SemiLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-Thin.woff2') format('woff2'),
    url('../font/planc/Planc-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-Regular.woff2') format('woff2'),
    url('../font/planc/Planc-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Planc';
  src: url('../font/planc/Planc-RegularItalic.woff2') format('woff2'),
    url('../font/planc/Planc-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

$--Planc: 'Planc', sans-serif;

$--main-blue: #113cf5;
$--secondary-color: #ff7976;
$--text-color: #212121;

$--max-width-desktop: 1200px;

body {
  background: #f9f9f9;
}

body,
input,
select,
textarea {
  font-family: $--Planc;
}

button {
  background: transparent;
  cursor: pointer;
  font-family: $--Planc;
}
