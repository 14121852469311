@import '../../assets/style/variables.module.scss';

.mFace {
  position: fixed;
  bottom: 20px;
  z-index: 10;
  right: 60px;
  display: flex;
  align-items: flex-end;
  height: 70px;

  .mFacePopup {
    background: #fefefe;
    position: relative;
    bottom: 40px;
    right: -1000px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 25px;
    font-size: 20px;
    line-height: 27px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    transition: all 0.5s ease;
    width: 0;

    a {
      color: $--main-blue;
      text-decoration: underline;
    }
  }

  &:hover {
    .mFacePopup {
      right: 20px;
      width: 360px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .mFace {
    right: 30px;
    width: 70vw;

    .mFaceButton {
      position: absolute;
      right: 0;
      background: 'red';
      width: 60px;
      height: 60px;
    }

    &:hover {
      .mFacePopup {
        right: -1000px;
        bottom: 80px;
        width: 100%;
      }
    }
  }

  .active {
    .mFacePopup {
      right: 10px !important;
      bottom: 80px !important;
      width: 100% !important;
    }
  }
}
