@import '../../assets/style/variables.module.scss';

.card {
  font-weight: bold;
  padding: 1em;
  text-align: right;
  color: #181a1a;
  box-sizing: border-box;

  width: 100%;
  height: 100%;
  box-shadow: 0 1px 20px rgba($color: #00000099, $alpha: 0.04);

  border-radius: 10px;
  // background-image: url(https://images.unsplash.com/photo-1557672199-6e8c8b2b8fff?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80);
  background-size: cover;

  position: relative;

  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0);
  cursor: pointer;
  background-color: #dddbdd;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 5s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
