@import '../../assets/style/variables.module.scss';

.userShare {
  background: #eeeeee;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  max-width: 100vw;
  display: flex;
  justify-content: center;

  .popupBackground {
    position: absolute;
    z-index: 1;
    width: 100vw;
    height: 0;
    background: linear-gradient(
      180deg,
      #1b1f31 0%,
      rgba(21, 43, 137, 0.6) 100%
    );
    backdrop-filter: blur(0px);
    opacity: 0;
    transition: all 0.5s ease;
  }

  .active {
    height: 100vh;
    backdrop-filter: blur(30px);
    opacity: 1;
  }

  .svgWrapper {
    position: absolute;
    top: -350px;
    width: 100%;
    height: 1000px;
    overflow: hidden;
    z-index: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .wrapper {
    width: 100%;
    padding: 0 50px;
    padding-top: 50px;

    .info {
      display: flex;
      align-items: flex-start;
      margin-top: 50px;

      .photo {
        object-fit: cover;
        object-position: center;
        width: 130px;
        height: 130px;
        border-radius: 5px;
        border: 1px solid rgba(17, 60, 245, 0.12);
        box-shadow: 0px 4px 19px 0px rgba(17, 60, 245, 0.26);
        margin-right: 20px;
      }

      .title {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h1 {
          color: $--main-blue;
          font-size: 52px;
          font-weight: 400;
        }

        h2 {
          font-size: 22px;
          color: $--secondary-color;
          font-weight: 400;
        }
      }

      .tagWrapper {
        margin-left: 30px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 250px;
        gap: 15px;

        .tag {
          font-size: 18px;
          color: #2f2f2f;
          border: 1px solid rgba(17, 60, 245, 0.18);
          background: white;
          padding: 10px 25px;
          border-radius: 5px;
        }
      }
    }

    .gridWrapper {
      width: 100%;
      margin-top: 50px;

      &:first-child {
        padding: 0 !important;
      }

      img {
        width: 100%;
      }
    }
  }
}

.masonry {
  margin: 0 !important;
  width: calc(100% + 32px);
  position: relative;
  left: -16px;
}
