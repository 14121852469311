@import '../../assets/style/variables.module.scss';

.wrapper {
  width: 100%;
  padding-top: 115px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(2) {
    width: calc(100% + 30px) !important;
  }

  .box {
    position: relative;
    // left: 10px;
    // padding-left: 50px;
    left: 7px;
    .masonry {
      width: calc(100% + 13px);
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    box-sizing: border-box;
    max-width: 100vw;
    overflow: hidden;
    padding-top: 75px;
    .box {
      padding: 20px !important;
      box-sizing: border-box;
      left: 0;
    }

    .masonry {
      margin: 0 !important;
      width: calc(100% + 2px) !important;
    }
  }
}
