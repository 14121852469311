@import '../../assets/style/variables.module.scss';

.popupWork {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;

  .wrapper {
    position: relative;

    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      .mainImage {
        max-height: 60vh;
        min-height: 60vh;
        max-width: 90vw;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
      }

      .navigate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 18px;

        .disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

    .bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;

      a {
        padding: 8px 6px;
        background: white;
        color: $--main-blue;
        height: unset;
        border-radius: 5px;
        position: absolute;
        right: 0;
        font-size: 10px;
      }

      .info {
        display: flex;
        align-items: flex-start;
        gap: 50px;

        .title {
          display: flex;
          flex-direction: column;
          gap: 10px;
          h3 {
            font-size: 14px;
            color: $--secondary-color;
            font-weight: 400;
          }
          h4 {
            font-size: 18px;
            color: white;
            font-weight: 700;
            margin-top: 6px;
          }
        }
        .avatar {
          display: flex;
          gap: 20px;
          align-items: center;

          div {
            display: flex;
            flex-direction: column;
            gap: 10px;
            h5 {
              font-size: 16px;
              font-weight: 400;
              color: white;
              max-width: 90px;
            }

            h6 {
              font-size: 12px;
              color: $--secondary-color;
              font-weight: 400;
            }
          }
          img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            object-position: center;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .popupWork {
    height: 100%;
    .wrapper {
      .center {
        .mainImage {
          max-height: unset;
          min-height: unset;
          max-height: 55vh;
          width: 90vw;
          min-width: unset;
        }
      }
      .bottom {
        gap: 30px;
        padding: 0 0;
        box-sizing: border-box;
        padding-top: 20px;
      }
    }
  }
}
