@import '../../assets/style/variables.module.scss';

.wrapper {
  width: 60%;
  padding-top: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: $--max-width-desktop;
  flex-direction: column;
  padding-bottom: 50px;
}

.faqCard {
  border-bottom: 1px solid $--main-blue;
  padding: 20px 0;
  width: 100%;
  .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    svg {
      transition: all 0.3s ease;
      path {
        fill: $--main-blue;
      }
    }

    h3 {
      font-size: 24px;
      color: $--main-blue;
      cursor: pointer;
    }
  }

  p {
    font-size: 24px;
    line-height: 35px;
    opacity: 0;
    transition: all 0.3s ease;
    max-height: 0;
    cursor: pointer;
    position: relative;
    z-index: -1;
  }
}

.active {
  svg {
    transform: rotate(45deg);
  }
  p {
    max-height: 1000px;
    opacity: 1;
    margin-top: 20px;
    position: static;
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    width: 100%;
    padding: 0 20px 0 26px;
    padding-top: 100px;
    box-sizing: border-box;
  }
}
