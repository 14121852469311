@import '../../assets/style/variables.module.scss';

.card {
  font-weight: bold;
  padding: 1em;
  text-align: right;
  color: #181a1a;
  box-sizing: border-box;

  width: 100%;
  height: 100%;
  box-shadow: 0 1px 20px rgba($color: #00000099, $alpha: 0.04);

  border-radius: 10px;
  // background-image: url(https://images.unsplash.com/photo-1557672199-6e8c8b2b8fff?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80);
  background-size: cover;

  position: relative;

  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0);
  cursor: pointer;
}

.home {
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 0.5s ease;
    opacity: 0;
    background: rgba(17, 60, 245, 0.35);
    border-radius: 10px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: 10px;
    opacity: 0;
    background: linear-gradient(360deg, #113cf5 0%, rgba(17, 60, 245, 0) 100%);
    transition: all 0.5s ease;
  }

  h5 {
    position: absolute;
    left: 18px;
    bottom: 18px;
    color: white;
    opacity: 0;
    transition: all 0.5s ease;
    z-index: 3;
    font-size: 16px;
    font-weight: 600;
  }

  &:hover {
    &::before,
    &::after {
      opacity: 1;
    }

    h5 {
      opacity: 1;
    }
  }
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.card:hover {
  transition-duration: 150ms;
  box-shadow: 0 1px 20px rgba($color: #00000099, $alpha: 0.18);
}

.card .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
  opacity: 0.5;
}
