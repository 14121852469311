@import '../../assets/style/variables.module.scss';

.works {
  width: 100%;
  padding: 0 50px;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .gridWrapper {
    width: 170%;
    padding-top: 115px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;

    .headWrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      right: -65px;
      margin-bottom: 30px;
      padding: 0 8px;
      width: calc(100% - 30px);
      gap: 30px;
    }

    &:nth-child(2) {
      position: relative;
      right: -10px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 960px) {
  .works {
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
    justify-content: flex-start;

    .gridWrapper {
      padding: 0;
      width: 100%;
      position: static !important;

      .headWrapper {
        position: static;
        padding: 0;
        width: calc(100%);
        margin-bottom: 10px;
        gap: 17px;
        padding: 0 37px;
        box-sizing: border-box;
        align-self: center;
      }
    }

    .box {
      box-sizing: border-box;
      padding: 0 20px;
    }

    .masonry {
      margin: 0 !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .works {
    .gridWrapper {
      .headWrapper {
        padding: 0 28px;
      }
    }
  }
}
