@import '../../assets/style/variables.module.scss';

.header {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  width: 100%;
  box-sizing: border-box;
  border-top: 10px solid $--main-blue;
  padding-top: 30px;
  align-items: flex-start;
  position: fixed;
  transition: all 0.5s ease;
  z-index: 100;

  .left {
    display: flex;
    gap: 56px;
  }

  .logo {
    margin-top: 5px;
  }

  ul {
    display: flex;
    gap: 38px;
    margin-top: 15px;
    min-width: 250px;
    li {
      a {
        font-size: 20px;
        color: $--main-blue;
        transition: all 0.5s ease;
        text-wrap: nowrap;
        overflow-wrap: break-word;
      }
    }
  }

  label {
    max-width: 750px;
    border-radius: 20px;
    height: 45px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease;
    z-index: 2;
    overflow: hidden;

    .recentBottom {
      display: grid;
      gap: 10px;
      padding: 0 18px;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      box-sizing: border-box;
      padding-right: 0;
      margin-top: 10px;

      .recent {
        width: 100%;
        padding: 0;
        .cardWrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
        }
      }
    }

    .recent {
      width: 100%;
      padding: 0 18px;
      box-sizing: border-box;
      margin-top: 20px;

      h5 {
        font-size: 14px;
        font-weight: 500;
        color: $--text-color;
      }

      .cardWrapper {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        margin-top: 15px;
      }
    }

    .labelWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      border-bottom: 1px solid #d3d3d3;

      button {
        transition: all 0.3s ease;
        svg {
          path {
            fill: $--main-blue;
          }
        }
      }
    }

    &:focus-within {
      border: 1px solid rgba(17, 60, 245, 0.21);
      height: 155px;
      box-shadow: -20px 20px 35px rgba(17, 60, 245, 0.3);

      input {
        border-radius: 0;
        position: relative;
      }

      button {
        padding-right: 20px;
      }
    }

    button {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      height: 45px;
      width: 90%;
      padding-left: 20px;
      border-radius: 20px;
      color: $--text-color;

      &::placeholder {
        color: #a5a5a5;
        font-size: 14px;
      }
    }
  }

  .focus {
    width: 45%;
    background: white;
    border: 1px solid rgba(17, 60, 245, 0.21);
    border: 1px solid rgba(17, 60, 245, 0.21);
    height: 155px;
    box-shadow: -20px 20px 35px rgba(17, 60, 245, 0.3);

    .labelWrapper {
      justify-content: space-between;

      button {
        padding-right: 20px;
        svg {
          path {
            fill: $--main-blue;
          }
        }
      }
    }
  }

  .focusWithoutRecent {
    height: 45px !important;
  }
}

.active {
  background: rgba(17, 60, 245, 0.7);
  padding: 15px 50px;
  border: 0px transparent solid;
  box-shadow: 0px 0px 71px 14px rgba(17, 60, 245, 0.25);
  backdrop-filter: blur(29px);
  border-bottom: 1px solid rgba($color: white, $alpha: 0.3);
  max-height: 72px;
  transition: all 0.5s ease;

  label {
    width: 80%;

    .labelWrapper {
      button {
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }

  ul {
    gap: 25px;
    li {
      a {
        font-size: 16px;
        color: white;
      }
    }
  }

  .logo {
    svg {
      path {
        fill: white;
      }
    }
  }
}

.shadowButton {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  // background: rgba($color: #000000, $alpha: 0.4);
  z-index: 1;
}

.popupActive {
  max-height: unset !important;
  padding: 15px 50px 100vh 50px !important;
  background: linear-gradient(180deg, #1b1f31 0%, rgba(21, 43, 137, 0.6) 100%);
  backdrop-filter: blur(30px);
}

@media only screen and (max-width: 1285px) {
  .header {
    label {
      width: 65%;
    }
  }
}
