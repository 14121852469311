@import '../../assets/style/variables.module.scss';

.searchCard {
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 35px;
  background: rgba(28, 28, 28, 0.15);
  padding: 5px 8px 5px 12px;
  height: 25px;
  gap: 10px;
  cursor: pointer;
  position: relative;

  .shadowClick {
    position: absolute;
    width: 70%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
  }

  p {
    color: rgba(47, 47, 47, 0.5);
  }
  button {
    height: 20px;
    padding-right: unset !important;
  }
}

.searchWorkCard {
  width: 100%;
  height: 180px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 40px rgba(17, 60, 245, 0.08);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.29);
    z-index: 1;
  }

  p {
    position: absolute;
    font-size: 22px;
    color: white;
    font-weight: 600;
    z-index: 2;
    bottom: 13px;
    left: 13px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    z-index: 0;
  }
}

.searchUserCard {
  width: 100%;
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 4px 40px rgba(17, 60, 245, 0.08);

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(17, 60, 245, 0.67) -8.87%,
      rgba(17, 60, 245, 0) 55.26%
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  svg {
    position: absolute;
    bottom: -220px;
    width: 120%;
    z-index: 1;
  }

  .info {
    position: absolute;
    bottom: 13px;
    left: 13px;
    z-index: 2;

    .title {
      font-size: 22px !important;
      color: white;
      font-weight: 600;
    }

    .subTitle {
      font-size: 16px !important;
      color: $--secondary-color;
      font-weight: 400;
      margin-top: 7px;
    }
  }

  img {
    min-width: 260px;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
}

.wideUserCard {
  width: calc(50% - 25px);
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  padding: 15px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;

  svg {
    position: absolute;
    z-index: 20;
    bottom: -80px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(17, 60, 245, 0.5);
    backdrop-filter: blur(12.5px);
  }

  .info,
  .main {
    position: relative;
    z-index: 2;
  }

  .main {
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
    border-radius: 15px;
    height: 213px;
    width: 213px;
    object-fit: cover;
    object-position: center;
  }

  .bg {
    position: absolute;
    width: 120%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    top: 0;
    left: -20px;
    z-index: 0;
  }

  .info {
    padding: 10px 25px 10px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 50%;

    .titleWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      padding-top: 30px;
      h5 {
        background: #f6f6f6;
        padding: 5px 11px 3px 11px;
        border-radius: 5px;
        font-size: 10px;
        color: $--main-blue;
        border: 1px solid rgba(17, 60, 245, 0.18);
        font-style: italic;
        font-weight: 300;
        margin-bottom: 13px;
        position: absolute;
        top: -10px;
      }
      h3 {
        font-size: 52px;
        font-weight: 400;
        font-style: italic;
        color: white;
        text-align: right;
        margin-bottom: 10px;
      }
      h4 {
        font-size: 22px;
        color: #ff7776;
        font-weight: 300;
        width: 100%;
        text-align: right;
      }
    }

    .tagWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
      width: 160px;
      justify-content: flex-end;

      button {
        background: #f6f6f6;
        padding: 8px 13px;
        border-radius: 5px;
        font-size: 14px;
        color: $--text-color;
        border: 1px solid rgba(17, 60, 245, 0.18);
        font-style: italic;
        font-weight: 300;
      }
    }
  }
}

.multiplayerCard {
  background: #d9d9d9;
  box-shadow: 0px 4px 40px rgba(17, 60, 245, 0.08);
  border-radius: 10px;
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .main {
    width: 48px;
    height: 48px;
    object-fit: cover;
    object-position: center;
    border: 1px solid rgba(17, 60, 245, 0.18);
    box-shadow: 0px 4px 40px rgba(17, 60, 245, 0.08);
    border-radius: 5px;
    position: relative;
    z-index: 1;
  }

  .info {
    padding: 14px;
    h3 {
      color: $--main-blue;
      font-weight: 600;
      font-size: 12px;
    }
    h4 {
      font-size: 10px;
      color: $--main-blue;
      font-weight: 200;
      margin-top: 3px;
    }
  }
}

.userCard {
  width: 100%;
  max-width: 255px;
  min-width: 255px;
  height: 350px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .tagWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    width: 120px;
    justify-content: flex-start;
    position: absolute;
    top: 25px;
    left: 25px;

    button {
      padding: 4px 4px 3px 4px;
      border-radius: 5px;
      font-size: 10px;
      color: white;
      border: 1px solid white;
      font-style: italic;
      font-weight: 300;
    }
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    max-width: 255px;
    background: linear-gradient(
      0deg,
      rgba(17, 60, 245, 0.67) -8.87%,
      rgba(17, 60, 245, 0) 55.26%
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  svg {
    position: absolute;
    bottom: -280px;
    width: 150%;
    height: 130%;
    z-index: 1;
    margin-left: -70px;
  }

  .info {
    position: absolute;
    bottom: 25px;
    left: 25px;
    z-index: 2;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 22px !important;
      color: white;
      font-weight: 600;
    }

    .subTitle {
      font-size: 16px !important;
      color: $--secondary-color;
      font-weight: 400;
      margin-top: 8px;
    }

    h5 {
      padding: 5px 8px 3px 8px;
      border-radius: 5px;
      font-size: 10px;
      color: $--main-blue;
      font-weight: 300;
      margin-bottom: 15px;
      background: white;
    }
  }

  img {
    min-width: 260px;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 960px) {
  .userCard {
    min-width: unset;
    height: 250px;

    .tagWrapper {
      top: 15px;
      left: 15px;
    }

    .info {
      left: 15px;

      .title {
        font-size: 16px !important;
      }

      .subTitle {
        font-size: 14px !important;
      }
    }
  }
}
