@import '../../assets/style/variables.module.scss';

.categories {
  width: 100%;
  padding: 0 50px;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;

  .info {
    padding-top: 115px;
    width: 25%;
    padding-bottom: 100px;

    &::-webkit-scrollbar {
      display: none;
    }

    h1 {
      font-weight: 400;
      color: $--main-blue;
      font-size: 46px;
    }

    .case {
      margin-top: 35px;
      h2 {
        color: $--text-color;
        font-size: 22px;
        font-weight: 400;
        color: #ff7976;
      }

      p {
        font-size: 15px;
        color: $--main-blue;
        leading-trim: both;
        text-edge: cap;
        line-height: 17px;
        margin-top: 15px;
        max-width: 250px;
      }
    }

    .tagWrapper {
      margin-top: 35px;

      h2 {
        color: $--text-color;
        font-size: 22px;
        font-weight: 400;
        color: #ff7976;
      }

      .tagGrid {
        display: flex;
        flex-wrap: wrap;
        width: 250px;
        gap: 12px;
        margin-top: 20px;
        a {
          background: #d9d9d9;
          border-radius: 5px;
          padding: 8px 10px;
          font-size: 12px;
          color: #424242;
        }
      }
    }

    .userWrapper {
      margin-top: 35px;

      h2 {
        color: $--text-color;
        font-size: 22px;
        font-weight: 400;
        color: #ff7976;
      }

      .userGrid {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 20px;
        width: 100%;
      }
    }
  }

  .right {
    width: 150%;
    padding-top: 115px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-bottom: 50px;
    padding-left: 0;
    padding-right: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    right: -70px;

    .headWrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 30px;
      padding: 0 0px;
      width: calc(100% - 30px);
      gap: 30px;
    }
  }

  .gridWrapper {
    width: 100%;

    &:nth-child(2) {
      position: relative;
      right: -16px;
      box-sizing: border-box;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 960px) {
  .categories {
    flex-direction: column;
    padding: 0 20px;
    height: 100%;

    .info {
      width: 100%;
      padding-bottom: 0;
      overflow: visible;
      padding-top: 120px;
    }

    .right {
      padding: 0;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: visible;
      margin-top: 20px;
      right: unset;

      .headWrapper {
        position: static;
        padding: 0;
        width: 100%;
        margin-bottom: 10px;
        gap: 17px;
      }

      .gridWrapper {
        position: relative;
        width: calc(100% + 30px);
        left: -15px;
      }

      .box {
        padding: 0px !important;
        box-sizing: border-box;
      }

      .masonry {
        margin: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .categories {
    .right {
      .gridWrapper {
        position: relative;
        width: calc(100% + 15px);
        left: -7.5px;
      }
    }
  }
}
